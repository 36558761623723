<template>
  <section>
    <b-row fluid>
      <b-col lg="12" cols="12" sm="12">
        <b-col cols="12">
          <b-card-actions
              ref="cari-kartlar"
              class="cari-liste-card"
              @refresh="refreshStop('cari-kartlar')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">{{
                      id == 0 || id == 'add' ? 'Cari Kartlar' : simpleGroupGet() + ' Grubuna Ait Cari Kartlar'
                    }}</h4>
                  <div v-if="width > 970" class="d-flex float-right">
                    <b-button
                        variant="flat-primary"
                        class="text-white float-right bg-info bg-lighten-1 mr-50"
                        v-if="id != 0 && id != 'add'"
                        :style="{backgroundColor:$store.getters.COLORS_GET.cardTitleButton + '!important'}"
                        @click="showAll"
                    >
                      Tüm Cari Kartları Göster
                    </b-button>
                    <b-button
                        variant="flat-primary"
                        class="text-white float-right bg-primary bg-lighten-2 border-0"
                        v-b-modal.new-cari
                        @click="isNewCari = true"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important', border:'none'}"
                    >
                      Cari Kart Oluştur
                    </b-button>
                  </div>
                </b-col>
              </div>
            </b-row>
            <b-row v-if="width < 970" class="mt-5">
              <b-col cols="12">
                <b-button
                    variant="flat-primary"
                    class="text-white float-right bg-primary bg-lighten-2 border-0"
                    v-b-modal.new-cari
                    @click="isNewCari = true"
                    :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important', border:'none'}"
                >
                  Cari Kart Oluştur
                </b-button>
                <b-button
                    variant="flat-primary"
                    class="text-white float-right bg-info bg-lighten-1 mr-50"
                    v-if="id != 0 && id != 'add'"
                    :style="{backgroundColor:$store.getters.COLORS_GET.cardTitleButton + '!important'}"
                    @click="showAll"
                >
                  Tüm Cari Kartları Göster
                </b-button>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-between flex-wrap" :class="width < 970 ? 'mt-2':'mt-4'">
              <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                <b-input-group
                    size="sm"
                    prepend="Gösterilecek kayıt sayısı"
                    class="unPrint"
                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="perPageChange($event)"
                      size="sm"
                  >
                  </b-form-select>
                </b-input-group>
              </b-form-group>
              <b-input-group size="sm" class="search-bar" :style="{width: width<1000?'50%!important':''}">
                <b-input-group-prepend>
                  <b-button
                      variant="outline-primary"
                      size="sm"
                      disabled
                      class="search-input mb-0"
                      :style="{height: width < 768 ? '30px':''}"
                  >
                    Ara
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" type="search"/>
              </b-input-group>
            </div>
            <hr/>
            <b-table
                striped
                hover
                responsive
                class="position-relative cari-group-table mt-1"
                :per-page="perPage"
                :items="listGet"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @row-clicked="goCariDetay"
            >
              <template #cell(firmaUnvan)="data">
                <p v-if="data.item.firmaKodu" v-b-tooltip.hover.top="data.item.firmaUnvan" id="firma-unvan-yetkili">{{
                    getFirmaUnvan(data.item.firmaUnvan)
                  }}{{ getFirmaUnvanDot(data.item.firmaUnvan) }}</p>
                <b-badge variant="primary" id="firma-unvan-badge" class="align-top yetkili-name"
                         v-if="data.item.adi ||data.item.soyAdi"
                >
                  {{ data.item.adi }}
                  {{ data.item.soyAdi }}
                </b-badge>
                <b-badge variant="primary" id="firma-unvan-badge" class="align-top yetkili-name ml-50">
                  <font-awesome-icon icon="fa-solid fa-phone"/>
                  {{ data.item.telefon }}
                </b-badge>
              </template>
              <template #cell(cariGrupID)="data">
                <feather-icon icon="UsersIcon"/>
                <strong class="ml-50">
                  {{ $store.getters.GRUP_NAME_GET(data.item.cariGrupID) }}
                </strong>
              </template>
              <template #cell(vergiNumarasi)="data">
                <font-awesome-icon icon="fa-regular fa-address-card"/>
                <strong class="ml-50">
                  {{ data.item.vergiNumarasi }}
                </strong>
              </template>
              <template #cell(bakiye)="data">
                <p class="p-0 m-0 d-block font-weight-bold" v-if="data.item.cariOzet.bakiye == 0">0₺</p>
                <p class="p-0 m-0 d-block font-weight-bold" v-if="data.item.cariOzet.bakiye != 0">{{
                    Number(data.item.cariOzet.bakiye)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                  }} TL</p>
                <p class="p-0 m-0 d-block">
                  <span class="text-success font-weight-bold" v-if="data.item.cariOzet.bakiye > 0"
                  >Tahsil Edilecek</span>
                  <span class="text-danger font-weight-bold" v-else-if="data.item.cariOzet.bakiye < 0">Ödenecek</span>
                  <span class="text-secondary font-weight-bold" v-else>Bakiye Yok</span>
                </p>
              </template>
              <template #cell(cariKartID)="data">
                <b-button
                    v-b-modal.cari-edit
                    @click="cariEdit(data.item)"
                    variant="gradient-primary"
                    class="btn-icon rounded-circle mr-50"
                    size="sm"
                    :style="{backgroundColor:$store.getters.COLORS_GET.bgWarning+'!important',border:'none'}"
                >
                  <font-awesome-icon icon="fa-solid fa-pen"/>
                </b-button>
                <b-button
                    @click="cariRemove(data.item.cariKartID)"
                    class="btn-icon rounded-circle trash-icon pull-up mr-50"
                    size="sm"
                    v-if="$store.getters.getRole == 1"
                    :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                >
                  <font-awesome-icon icon="fa-solid fa-trash-can"/>
                </b-button>
                <b-button
                    variant="gradient-warning"
                    class="btn-icon rounded-circle mr-50"
                    size="sm"
                    :to="'/cariEkstre/'+data.item.cariKartID"
                    :style="{backgroundColor:$store.getters.COLORS_GET.bgPrimary+'!important',border:'none'}"
                >
                  <font-awesome-icon icon="fa-solid fa-file-contract"/>
                </b-button>
                <b-button
                    variant="gradient-success"
                    class="btn-icon rounded-circle mr-50"
                    size="sm"
                    :to="'/cariDetay/'+data.item.cariKartID"
                    :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                >
                  <font-awesome-icon icon="fa-solid fa-eye"/>
                </b-button>
              </template>
            </b-table>
            <hr/>
            <b-row class="d-flex align-items-center mt-1">
              <b-col cols="6">
                <p class="text-muted">Toplam {{ totalRows }} Kayıt bulundu</p>
              </b-col>
              <b-col cols="6">
                <b-pagination
                    v-model="currentPage"
                    @change="currentPageChange($event)"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0 float-right"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-col>
    </b-row>
    <b-modal
        id="cari-edit"
        ok-only
        hide-footer
        centered
        size="lg"
        header-bg-variant="white"
    >
      <b-form>
        <form autocomplete="off">
          <b-row>
            <b-col cols="12">
              <h3>
                <b-badge variant="primary" class="modalBadge"
                         :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                >
                  Cari Bilgileri
                </b-badge>
              </h3>
            </b-col>
            <b-col cols="6" class="mt-1">
              <b-form-group label="İsim" label-for="basicInput">
                <b-form-input v-model="editCari.adi" id="basicInput" autocomplete="off"/>
              </b-form-group>
            </b-col>
            <b-col cols="6" class="mt-1">
              <b-form-group label="Soyisim" label-for="soy-isim">
                <b-form-input v-model="editCari.soyAdi" id="soy-isim" autocomplete="off"/>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Cari Grup" label-for="basicInput">
                <model-list-select
                    class=""
                    v-model="editCari.cariGrupID"
                    :list="groupGet"
                    option-value="cariGrupID"
                    option-text="grupAdi"

                    placeholder="Cari Grup Seçiniz"
                >
                </model-list-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="E-Posta" label-for="basicInput">
                <b-form-input v-model="editCari.ePosta" id="basicInput" autocomplete="off"/>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Telefon" label-for="basicInput">
                <b-form-input v-model="editCari.telefon" id="basicInput" autocomplete="off"/>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="İl"
              >
                <model-list-select
                    class=""
                    :list="json"
                    v-model="editCari.il"
                    option-value="il_adi"
                    option-text="il_adi"

                    name="unvan"
                    placeholder="İl Seçiniz"
                >
                </model-list-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="İlçe"
              >
                <model-list-select
                    class=""
                    :list="ilceler"
                    v-model="editCari.ilce"
                    option-value="ilce_adi"
                    option-text="ilce_adi"

                    placeholder="İlçe Seçiniz"
                >
                </model-list-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Adres" label-for="basicInput">
                <b-form-input v-model="editCari.adres" id="basicInput" autocomplete="off"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Cari Not" label-for="basicInput">
                <b-form-input v-model="editCari.cariNot" id="basicInput" autocomplete="off"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <h3>
                <b-badge variant="success" class="modalBadge"
                         :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                >
                  Fatura Bilgileri
                </b-badge>
              </h3>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Firma Ünvanı"
                  label-for="basicInput"
                  class="mt-1"
              >
                <b-form-input v-model="editCari.firmaUnvan" id="basicInput" autocomplete="off"/>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="Vergi No / TC NO"
                  label-for="basicInput"
                  class="mt-1"
              >
                <b-form-input v-model="editCari.vergiNumarasi" id="basicInput" autocomplete="off"/>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="Banka"
                  label-for="basicInput"
                  class="mt-1"
              >
                <b-form-input v-model="editCari.banka" id="basicInput" autocomplete="off"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="İBAN"
                  label-for="basicInput"
                  class="mt-1"
              >
                <b-input-group @paste="ibanPaste2">
                  <b-input-group-prepend is-text>
                    TR
                  </b-input-group-prepend>
                  <cleave
                      id="phone"
                      v-model="editCari.ibann"
                      class="form-control"
                      :options="options.block"
                      placeholder="00 0000 0000 0000 0000 0000 00"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Vergi Dairesi"
                  label-for="basicInput"
                  class="mt-1"
              >
                <b-form-input v-model="editCari.vergiDairesi" id="basicInput" autocomplete="off"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="d-flex justify-content-end">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  @click="editCari.adi && editCari.soyAdi && editCari.cariGrupID && (editCari.vergiNumarasi.length == 10 || editCari.vergiNumarasi.length == 11) ? cariUpdate() : false"
                  :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none',cursor: !editCari.adi || !editCari.soyAdi || !editCari.cariGrupID || !(editCari.vergiNumarasi.length == 10 || editCari.vergiNumarasi.length == 11) ? 'default':'pointer',opacity: !editCari.adi || !editCari.soyAdi || !editCari.cariGrupID || !(editCari.vergiNumarasi.length == 10 || editCari.vergiNumarasi.length == 11) ? 0.5: 1}"
                  v-b-tooltip="!editCari.adi ? 'Cari Adı Zorunludur.' :  !editCari.soyAdi  ?'Cari Soyismi Zorunludur.' : !editCari.cariGrupID ? 'Cari Grubu Zorunludur.': !(editCari.vergiNumarasi.length == 10 || editCari.vergiNumarasi.length == 11) ? 'Vergi numarası 10 haneli veya TC Kimlik numarası 11 haneli olmalıdır.':'' "
              >
                Güncelle
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-form>
    </b-modal>
    <b-modal
        id="new-cari"
        ok-only
        hide-footer
        centered
        size="lg"
        no-close-on-esc
        no-close-on-backdrop
        hide-header
    >
      <form autocomplete="off">
        <b-row>
          <b-col cols="12">
            <h3>
              <b-badge variant="primary" class="modalBadge"
                       :style="{backgroundColor:$store.getters.COLORS_GET.bgPrimary+'!important',border:'none'}"
              >
                Cari Bilgileri
              </b-badge>
            </h3>
          </b-col>
          <b-col cols="6" class="mt-1">
            <b-form-group label="İsim" label-for="basicInput">
              <b-form-input autocomplete="off" v-model="addCari.adi" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="6" class="mt-1">
            <b-form-group label="Soyisim" label-for="soy-isim">
              <b-form-input autocomplete="off" v-model="addCari.soyAdi" id="soy-isim"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Cari Grup" label-for="basicInput">
              <model-list-select
                  class=""
                  v-model="addCari.cariGrupID"
                  :list="groupGet"
                  option-value="cariGrupID"
                  option-text="grupAdi"

                  placeholder="Cari Grup Seçiniz"
              >
              </model-list-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="E-Posta" label-for="basicInput">
              <b-form-input autocomplete="off" v-model="addCari.ePosta" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Telefon" label-for="basicInput">
              <b-form-input autocomplete="off" v-model="addCari.telefon" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="İl"
                label-for="basicInput"
            >
              <model-list-select
                  class=""
                  :list="json"
                  v-model="addCari.il"
                  option-value="il_adi"
                  option-text="il_adi"

                  name="unvan"
                  placeholder="İl Seçiniz"
              >
              </model-list-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="İlçe"
                label-for="basicInput"
            >
              <model-list-select
                  class=""
                  :list="ilceler"
                  v-model="addCari.ilce"
                  option-value="ilce_adi"
                  option-text="ilce_adi"

                  placeholder="İlçe Seçiniz"
              >
              </model-list-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Adres" label-for="basicInput">
              <b-form-input autocomplete="off" v-model="addCari.adres" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Cari Not" label-for="basicInput">
              <b-form-input autocomplete="off" v-model="addCari.cariNot" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h3>
              <b-badge variant="success" class="modalBadge"
                       :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
              >
                Fatura Bilgileri
              </b-badge>
            </h3>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Firma Ünvanı"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input autocomplete="off" v-model="addCari.firmaUnvan" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Vergi No / TC NO"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input autocomplete="off" v-model="addCari.vergiNumarasi" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Banka"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input autocomplete="off" v-model="addCari.banka" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="İBAN"
                label-for="basicInput"
                class="mt-1"
            >
              <b-input-group @paste="ibanPaste">
                <b-input-group-prepend is-text>
                  TR
                </b-input-group-prepend>
                <cleave
                    v-model="addCari.ibann"
                    class="form-control"
                    :options="options.block"
                    placeholder="00 0000 0000 0000 0000 0000 00"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Vergi Dairesi"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input autocomplete="off" v-model="addCari.vergiDairesi" id="basicInput"/>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-50 text-white"
                @click="addCari.adi && addCari.soyAdi && addCari.cariGrupID && (addCari.vergiNumarasi.length == 10 || addCari.vergiNumarasi.length == 11) ? cariAdd() : false"
                :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none',cursor: !addCari.adi || !addCari.soyAdi || !addCari.cariGrupID || !(addCari.vergiNumarasi.length == 10 || addCari.vergiNumarasi.length == 11) ? 'default':'pointer',opacity: !addCari.adi || !addCari.soyAdi || !addCari.cariGrupID || !(addCari.vergiNumarasi.length == 10 || addCari.vergiNumarasi.length == 11) ? 0.5: 1}"
                v-b-tooltip="!addCari.adi ? 'Cari Adı Zorunludur.' :  !addCari.soyAdi  ?'Cari Soyismi Zorunludur.' : !addCari.cariGrupID ? 'Cari Grubu Zorunludur.': !(addCari.vergiNumarasi.length == 10 || addCari.vergiNumarasi.length == 11) ? 'Vergi numarası 10 haneli veya TC Kimlik numarası 11 haneli olmalıdır.':'' "
            >
              Kaydet
            </b-button>
            <b-button
                @click="newCariReset"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mr-50 text-white"
                variant="outline-warning"
                :style="{backgroundColor:$store.getters.COLORS_GET.bgWarning+'!important',border:'none!important'}"
            >
              Sıfırla
            </b-button>
            <b-button
                @click="newHide"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                class="text-white"
                :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
            >
              Kapat
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <b-sidebar
        id="add-grup"
        :visible="isNewGroup"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        right
        @change="(val) => $emit('update:is-add-grup-active', val)"
        ref="newCariGrup"
    >
      <template>
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Cari Grup Ekle
          </h5>
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="newGroupHidden"
          />
        </div>
        <b-form @submit.prevent id="new-cari-form">
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Grup Adı"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="vi-first-name"
                      v-model="grupName"
                      autocomplete="off"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Açıklama"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="AlignCenterIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="vi-first-name"
                      v-model="grupContent"
                      autocomplete="off"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Grup Türü"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <model-list-select
                      class=""
                      :list="grupTuruOptions"
                      v-model="grupTuru"
                      option-value="value"
                      option-text="text"

                      name="unvan"
                      placeholder="Hızlı Seçim"
                  >
                  </model-list-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="!grupName || !grupTuru"
                  @click="grupSave('kayit')"
              >
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                Kaydet
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="newGroupHidden"
              >
                Vazgeç
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import _ from 'lodash'
import axios from 'axios'
import router from '@/router'
import updateModal from './cariUpdateModal.vue'
import Cleave from 'vue-cleave-component'
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const json = require('@/assets/turkey-cities.json').data
    const ilceler = []
    const {
      width,
    } = useWindowSize()
    return {
      json,
      ilceler,
      width,
    }
  },
  components: {
    Cleave,
    BSidebar,
    updateModal,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      options: {
        block: {
          blocks: [2, 4, 4, 4, 4, 4, 2],
          uppercase: true,
        },
      },
      changeCariGrupID: '',
      isNewCari: false,
      id: this.$route.params.id,
      grupContent: '',
      grupName: '',
      grupTuruOptions: [
        {
          value: '1',
          text: 'Müşteriler',
        }, {
          value: '2',
          text: 'Tedarikçiler',
        }, {
          value: '3',
          text: 'Personeller',
        }, {
          value: '4',
          text: 'Diğer',
        },
      ],
      grupTuru: {
        value: '1',
        text: 'Müşteriler',
      },
      isNewGroup: false,
      addCari: {
        cariGrupID: '',
        cariCardID: '',
        adi: '',
        soyAdi: '',
        adres: '',
        ePosta: '',
        telefon: '',
        firmaUnvan: '',
        vergiNumarasi: '',
        vergiDairesi: '',
        il: '',
        ilce: '',
        cariNot: '',
        banka: '',
        ibann: '',
      },
      editCari: {
        cariGrupID: '',
        cariCardID: '',
        adi: '',
        soyAdi: '',
        adres: '',
        ePosta: '',
        telefon: '',
        firmaUnvan: '',
        vergiNumarasi: '',
        vergiDairesi: '',
        il: '',
        ilce: '',
        cariNot: '',
        banka: '',
        ibann: '',
      },
      degismeyenData: [],
      fields: [
        {
          key: 'firmaUnvan',
          label: 'FİRMA ÜNVANI',
          sortable: true,
          thStyle: {
            width: '25%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '25%' },
        },
        {
          key: 'cariGrupID',
          label: 'CARİ GRUP',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'vergiNumarasi',
          label: 'TC KİMLİK / VERGİ NO',
          sortable: true,
          thStyle: {
            width: '19%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '19%' },
        },
        {
          key: 'bakiye',
          label: 'BAKİYE',
          sortable: true,
          thStyle: {
            width: '13%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '13%' },
        },
        {
          key: 'cariKartID',
          label: 'İşlemler',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
      ],
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      toplamKayit: 1,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      grupList: [],
    }
  },
  computed: {
    listGet() {
      return Object.values(store.getters.CARI_CARD_GET)
    },
    groupGet() {
      let grupList = []
      grupList.push(Object.values(store.getters.CARI_GROUP_GET))
      grupList[0].push({
        grupAdi: 'Yeni Cari Grup Oluştur',
        cariGrupID: '-1',
        yeni: true,
      })
      return _.orderBy(grupList[0], ['yeni', 'toplamCari'], ['asc', 'desc'])
    },
  },
  watch: {
    addCari: {
      deep: true,
      handler(newVal) {
        if (newVal.cariGrupID == -1) {
          this.$bvModal.hide('new-cari')
          this.$root.$emit('bv::toggle::collapse', 'add-grup')
          this.isNewGroup = true
        }
        // SEHIR_ATAMA
        if (newVal.il) {
          this.ilceler = this.json.find(x => x.il_adi === newVal.il).ilceler
        }
      },
    },
    editCari: {
      deep: true,
      handler(newVal) {
        if (newVal.cariGrupID == -1) {
          this.$bvModal.hide('cari-edit')
          this.$root.$emit('bv::toggle::collapse', 'add-grup')
          this.isNewGroup = true
        }
        // SEHIR_ATAMA
        if (newVal.il) {
          this.ilceler = this.json.find(x => x.il_adi === newVal.il).ilceler
        }
      },
    },
    '$route.params.id': function (newVal) {
      store.dispatch('cariCardlar', {
        uzunluk: this.perPage,
        baslangic: 0,
        cariGrupID: newVal,
      })
          .then(res => {
            this.toplamKayit = Object.values(this.$store.getters.CARI_CARD_GET).length
            this.totalRows = res.toplamKayit
          })
    },
    filter(newVal) {
      this.$refs['cari-kartlar'].showLoading = true
      this.$store
          .dispatch('cariCardlar', {
            uzunluk: this.perPage,
            baslangic: 0,
            searchKey: newVal,
            cariGrupID: this.id,
          })
          .then(res => {
            this.$refs['cari-kartlar'].showLoading = false
            this.toplamKayit = Object.values(this.$store.getters.CARI_CARD_GET).length
            this.totalRows = res.toplamKayit
          })
    },
  },
  methods: {
    getFirmaUnvan(unvan) {
      return unvan?.slice(0, 40)
    },
    getFirmaUnvanDot(unvan) {
      return unvan?.length > 40 ? '...' : ''
    },
    ibanPaste(event) {
      const veri = event.clipboardData.getData('text')
      if (veri.includes('TR')) {
        this.addCari.ibann = veri.slice(2, 32)
      } else {
        this.addCari.ibann = veri.slice(0, 40)
      }
    },
    ibanPaste2(event) {
      const veri = event.clipboardData.getData('text')
      if (veri.includes('TR')) {
        this.editCari.ibann = veri.slice(2, 32)
      } else {
        this.editCari.ibann = veri.slice(0, 40)
      }
    },
    showAll() {
      this.$router.push({ path: '/cariListe/0' })
      this.id = 0
    },
    simpleGroupGet() {
      return store.getters.GRUP_NAME_GET(this.id)
    },
    goCariDetay(item) {
      router.push({
        name: 'cariDetay',
        params: { id: item.cariKartID },
      })
    },
    grupSave(islem) {
      store.dispatch('cariGroupSave', {
        tur: islem === 'kayit' ? this.grupTuru.value : this.editData.grupTuru.value,
        adi: islem === 'kayit' ? this.grupName : this.editData.grupAdi,
        aciklama: islem === 'kayit' ? this.grupContent : this.editData.aciklama,
        cariGrupID: islem === 'edit' ? this.editData.cariGrupID : '',
        islem,
      })
          .then((res, position = 'bottom-right') => {
            this.$refs.newCariGrup.hide()
            if (this.isNewCari) {
              this.$bvModal.show('new-cari')
              this.addCari.cariGrupID = res.result.data.cariGrupID
            } else {
              this.editCari.cariGrupID = res.result.data.cariGrupID
              this.$bvModal.show('cari-edit')
            }
            this.grupName = ''
            this.grupContent = ''
            this.grupTuruDefault()
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            this.addCari.cariGrupID = ''
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    newGroupHidden() {
      this.addCari.cariGrupID = ''
      this.changeCariGrupID ? this.editCari.cariGrupID = this.changeCariGrupID : this.editCari.cariGrupID = ''
      this.isNewCari ? this.$bvModal.show('new-cari') : this.$bvModal.show('cari-edit')
      this.isNewGroup = false
    },
    newCariReset() {
      this.addCari.adi = ''
      this.addCari.soyAdi = ''
      this.addCari.adres = ''
      this.addCari.ePosta = ''
      this.addCari.telefon = ''
      this.addCari.firmaUnvan = ''
      this.addCari.vergiDairesi = ''
      this.addCari.vergiNumarasi = ''
      this.addCari.il = ''
      this.addCari.ilce = ''
      this.addCari.cariNot = ''
      this.addCari.banka = ''
      this.addCari.ibann = ''
    },
    newHide() {
      if (this.id == 'add') {
        router.push({ path: '/cariListe/0' })
      }
      this.$bvModal.hide('new-cari')
      const t = this
      t.isNewCari = false
      t.addCari.adi = ''
      t.addCari.soyAdi = ''
      t.addCari.adres = ''
      t.addCari.ePosta = ''
      t.addCari.telefon = ''
      t.addCari.firmaUnvan = ''
      t.addCari.vergiDairesi = ''
      t.addCari.vergiNumarasi = ''
      t.addCari.il = ''
      t.addCari.ilce = ''
      t.addCari.cariNot = ''
      t.addCari.banka = ''
      t.addCari.ibann = ''
    },
    cariAdd() {
      store.dispatch('cariCardSave', {
        adi: this.addCari.adi,
        soyAdi: this.addCari.soyAdi,
        adres: this.addCari.adres,
        ePosta: this.addCari.ePosta,
        telefon: this.addCari.telefon,
        faturaUnvan: this.addCari.firmaUnvan,
        vergiDairesi: this.addCari.vergiDairesi,
        vergiNumarasi: this.addCari.vergiNumarasi,
        // bakiye: this.addCari.bakiye,
        cariNot: this.addCari.cariNot,
        il: this.addCari.il,
        ilce: this.addCari.ilce,
        banka: this.addCari.banka,
        ibann: this.addCari.ibann,
        cariGrupID: this.addCari.cariGrupID,
      })
          .then((res, position = 'bottom-right') => {
            const mesaj = store.getters.notificationSettings(res)
            if (res.result.status == 200) {
              this.isNewCari = false
              this.$router.push({
                name: 'cariDetay',
                params: {
                  id: res.data.cariKartID,
                },
              })
              this.newCariReset()
              this.$bvModal.hide('new-cari')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'warning',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    cariUpdate() {
      var guncellenenKeyler = {}
      Object.entries(this.degismeyenData)
          .forEach(entry => {
            const [key, value] = entry
            if (this.editCari[key] && this.editCari[key] != value) {
              guncellenenKeyler[key] = this.editCari[key]
            }
          })
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken)
      fd.append('serviceName', 'cari')
      fd.append('methodName', 'cariDetay')
      fd.append('islem', 'cariDuzenle')
      fd.append('cariKartID', this.editCari.cariCardID)
      Object.entries(guncellenenKeyler)
          .forEach(entry => {
            const [key, value] = entry
            fd.append(`updateList[${key}]`, value)
          })
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              this.changeCariGrupID = ''
              this.$bvModal.hide('cari-edit')
              store.commit('setToken', res.data.result.userToken)
              store.commit('CARI_CARD_ACTION', res.data)
              store.dispatch('cariCardlar', {
                searchKey: this.filter,
                uzunluk: this.perPage,
                baslangic: 0,
                cariGrupID: this.id,
              })
                  .then(res => {
                    this.totalRows = res.toplamKayit
                  })
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    cariEdit(item) {
      if (item.il) {
        this.ilceler = this.json.find(x => x.il_adi === item.il).ilceler
      }
      this.changeCariGrupID = item.cariGrupID
      this.editCari.cariGrupID = item.cariGrupID
      this.editCari.cariCardID = item.cariKartID
      this.editCari.adi = item.adi
      this.editCari.soyAdi = item.soyAdi
      this.editCari.adres = item.adres
      this.editCari.ePosta = item.ePosta
      this.editCari.telefon = item.telefon
      this.editCari.firmaUnvan = item.firmaUnvan
      this.editCari.vergiNumarasi = item.vergiNumarasi
      this.editCari.vergiDairesi = item.vergiDairesi
      this.editCari.bakiye = item.bakiye
      this.editCari.il = item.il
      this.editCari.ilce = item.ilce
      this.editCari.cariNot = item.cariNot
      this.editCari.banka = item.banka
      this.editCari.ibann = item.ibann
      this.degismeyenData = item
    },
    perPageChange(newVal) {
      const veri = this.currentPage * newVal - newVal
      this.$refs['cari-kartlar'].showLoading = true
      this.$store
          .dispatch('cariCardlar', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.filter,
            cariGrupID: this.id,
          })
          .then(res => {
            this.$refs['cari-kartlar'].showLoading = false
            this.toplamKayit = Object.values(this.$store.getters.CARI_CARD_GET).length
            this.totalRows = res.toplamKayit
          })
    },
    currentPageChange(newVal) {
      const veri = newVal * this.perPage - this.perPage
      this.$refs['cari-kartlar'].showLoading = true
      this.$store
          .dispatch('cariCardlar', {
            uzunluk: this.perPage,
            baslangic: veri,
            searchKey: this.filter,
            cariGrupID: this.id,
          })
          .then(res => {
            this.$refs['cari-kartlar'].showLoading = false
            this.toplamKayit = Object.values(this.$store.getters.CARI_CARD_GET).length
            this.totalRows = res.toplamKayit
          })
    },
    cariRemove(id) {
      store.dispatch('cariCardRemove', id)
          .then((res, position = 'bottom-right') => {
            const veri = this.currentPage * this.perPage - this.perPage
            store.dispatch('cariCardlar', {
              uzunluk: this.perPage,
              baslangic: veri || 0,
              searchKey: this.filter,
              cariGrupID: this.id,
            })
                .then(res => {
                  this.totalRows = res.toplamKayit
                })
            if (res.result.status == 200) {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else if (res.result.status == 303) {
              this.$swal({
                title: res.result.errMsg,
                text: res.result.helpMsg,
                icon: 'warning',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                showCloseButton: true,
                buttonsStyling: false,
              })
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'cari-kartlar'].showLoading = false
      }, 1000)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    grupTuruDefault() {
      this.grupTuru = {
        value: '1',
        text: 'Müşteriler',
      }
    },
  },
  mounted() {
    if (this.id == 'add') {
      this.$bvModal.show('new-cari')
    }
  },
  created() {
    store.dispatch('cariGrup')
        .then(res => {
          this.grupList = res.cariGrupListesi
        })
    store.dispatch('cariCardlar', {
      uzunluk: this.perPage,
      baslangic: 0,
      cariGrupID: this.id == 'add' ? 0 : this.id,
    })
        .then(res => {
          this.totalRows = res.toplamKayit
          this.toplamKayit = Object.values(this.$store.getters.CARI_CARD_GET).length
        })
  },
  beforeDestroy() {
    store.commit('CARI_LIST_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style>
.fa-exchange-alt {
  display: none !important;
}

.msl-searchable-list__items {
  border-top: none !important;
}

.cari-group-table .table thead th, table tbody td {
  padding: 0.4rem !important;
}

.cari-liste-card .card-header, .cari-group-add .card-header, .cari-group-edit .card-header {
  display: none !important;
}

.transfer-modal .msl-multi-select__list {
  width: 43% !important;
}
</style>
<style scoped>
#new-cari-form {
  padding: 30px !important;
}

.yetkili-name {
  background-color: #3F979B !important;
}

.search-bar {
  width: 20% !important;
}

.transfer-modal ::-webkit-scrollbar {
  width: 7px !important;
}

.transfer-modal ::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.transfer-modal ::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.transfer-modal ::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
}

.cari-group-edit .card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
  background-color: #F48484 !important;
}

.col-8, .col-4 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.item-group-tur {
  position: absolute !important;
  margin-left: 3%;
}

.list-group-item {
  border: none !important;
  cursor: pointer !important;
  transition: 200ms;
}

.list-group-item:hover, span p {
  transition: 200ms;
  color: black !important;
}

/*@media only screen and (max-width: 1000px) {*/
/*  .search-bar {*/
/*    !*width: 20% !important;*!*/
/*    display: none !important;*/
/*  }*/
/*}*/

.search-input {
  border-color: #c3baba !important;
}

#filterInput:focus {
  border-color: #c3baba !important;
}

h4 {
  margin-bottom: 0 !important;
  padding: 6px;
}

.cari-group-table button {
  border: none !important;
}

.transfer-icon {
  background-color: #1F8A70 !important;
}

.trash-icon {
  background-color: #F55050 !important;
}

.transfer-modal .form-group {
  width: 100% !important;
  float: right !important;
}

.msl-multi-select {
  width: 108% !important;
}

#firma-unvan-yetkili {
  margin-top: 2px !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

#bakiye-badge {
  font-size: 14px !important;
}

</style>
